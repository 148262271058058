import {
  HttpClient,
  HttpEventType,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { INSTANCE_CODE } from '@konnektu/tokens';
import { filter, map } from 'rxjs';
import {
  CommunicationMessageDto,
  CommunicationMessageDtoResponse,
  CommunicationMessageSendsDto,
  CommunicationTemplateDto,
  CommunicationTemplateMessageDto,
  CommunicationTemplateResponse,
  CreateIvrRequest,
  CreateIvrResponse,
  GetMessageSendParams,
  GetTemplatesParameters,
  VoiceBoxCampaign
} from './models';

@Injectable({ providedIn: 'root' })
export class CommunicationApiService {
  private readonly httpClient = inject(HttpClient);

  private readonly tenantCode = inject(INSTANCE_CODE);

  getTemplate(templateId: number) {
    return this.httpClient
      .get<CommunicationTemplateResponse>(
        `${this.tenantCode}/api/core/v1/communications/templates/${templateId}`
      )
      .pipe(
        map(
          (resp) =>
            ({
              ...resp,
              validFrom: resp.validFrom
                ? new Date(resp.validFrom)
                : resp.validFrom,
              validTo: resp.validTo ? new Date(resp.validTo) : resp.validTo
            }) as CommunicationTemplateDto
        )
      );
  }

  getTemplates(params?: GetTemplatesParameters) {
    let httpParams = new HttpParams();
    if (params?.limit) {
      httpParams = httpParams.set('limit', params.limit);
    }
    if (params?.offset) {
      httpParams = httpParams.set('offset', params.offset);
    }
    if (params?.include?.settings) {
      httpParams = httpParams.append('include', 1);
    }
    if (params?.include?.template) {
      httpParams = httpParams.append('include', 2);
    }
    return this.httpClient
      .get<
        CommunicationTemplateResponse[]
      >(`${this.tenantCode}/api/core/v1/communications/templates`, { params: httpParams })
      .pipe(
        map((resp) =>
          resp.map(
            (r) =>
              ({
                ...r,
                validFrom: r.validFrom ? new Date(r.validFrom) : r.validFrom,
                validTo: r.validTo ? new Date(r.validTo) : r.validTo
              }) as CommunicationTemplateDto
          )
        )
      );
  }

  getSenderNames(providerId: number) {
    return this.httpClient.get<string[]>(
      `${this.tenantCode}/api/uzilla/v1/communications/providers/${providerId}/senderNames`
    );
  }

  getSenderEmails(providerId: number) {
    return this.httpClient.get<string[]>(
      `${this.tenantCode}/api/uzilla/v1/communications/providers/${providerId}/senderEmails`
    );
  }

  getVoiceBoxCampaigns(providerId: number) {
    const queryParams = new HttpParams().append('providerId', providerId);
    return this.httpClient.get<VoiceBoxCampaign[]>(
      `${this.tenantCode}/api/uzilla/v1/communications/voicebox/campaigns`,
      { params: queryParams }
    );
  }

  getTemplateMessages(templateId: number) {
    return this.httpClient.get<CommunicationTemplateMessageDto[]>(
      `${this.tenantCode}/api/core/v1/communications/templates/${templateId}/messages`
    );
  }

  getMessage(messageId: number) {
    return this.httpClient
      .get<CommunicationMessageDtoResponse>(
        `${this.tenantCode}/api/core/v1/communications/messages/${messageId}`
      )
      .pipe(
        map(
          (resp) =>
            ({
              ...resp,
              startedAt: new Date(resp.startedAt),
              finishedAt: resp.finishedAt
                ? new Date(resp.finishedAt)
                : resp.finishedAt
            }) as CommunicationMessageDto
        )
      );
  }

  getMessageSends(messageId: number, params?: GetMessageSendParams) {
    const httpParams = new HttpParams({
      fromObject: params as Record<string, any>
    });
    return this.httpClient.get<CommunicationMessageSendsDto[]>(
      `${this.tenantCode}/api/core/v1/communications/messages/${messageId}/sends`,
      { params: httpParams }
    );
  }

  createIvrCommunication(request: CreateIvrRequest) {
    return this.httpClient.post<CreateIvrResponse>(
      `${this.tenantCode}/api/core/v1/communications/templates`,
      request
    );
  }

  getCallCommunicationRecording(id: number) {
    return this.httpClient
      .request('GET', `${this.tenantCode}/ui-api/calls/${id}/recording`, {
        observe: 'events',
        responseType: 'blob'
      })
      .pipe(
        filter((e) => e.type === HttpEventType.Response),
        map((e) => (e as HttpResponse<Blob>).url)
      );
  }
}

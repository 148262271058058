export enum CommunicationType {
  Bulk = 1,
  Campaign
}

export enum CommunicationChannel {
  Email = 1,
  Sms,
  Push,
  WhatsApp,
  Viber,
  Telegram,
  Ivr,
  PhoneCall
}

export enum CommunicationActivityType {
  NotSent = 1,
  Sent = 2,
  Delivered = 3,
  Opened = 4,
  Click = 5,
  Spam = 6,
  Unsubscribed = 7,
  Subscriptions = 8,
  Confirmations = 9,
  Dismiss = 10,
  Foreground = 11,
  Goals = 12,
  Expired = 13,
  Deleted = 14,
  UnknownStatus = 15,
  CallAnswered = 16,
  CallNotAnswered = 17,
  PhoneBusy = 18
}

export enum CommunicationDispatchStatus {
  Created = 1,
  Sending = 2,
  AllSuccess = 3,
  SendingFailed = 4,
  Cancelled = 5,
  Validating = 6,
  ValidationFailed = 7,
  PartialSuccess = 8,
  AllDeclined = 9
}

export enum CommunicationIdentifierStatus {
  AcceptedByProvider = 1,
  ValidationError,
  ProviderError
}

export interface VoiceBoxCampaign {
  id: number;
  name: string;
  methodId: string;
}

export interface CreateIvrRequest {
  name: string | null;
  providerId: number | null;
  type: number | null;
  channel: number;
  settings: {
    CampaignId: string | null;
    CampaignName: string | null;
  };
}

interface Settings {
  CampaignId: string;
  CampaignName: any;
}

interface Provider {
  id: number;
  name: string;
  channel: number;
}

export interface CreateIvrResponse {
  settings: Settings;
  id: number;
  name: string;
  type: number;
  provider: Provider;
}

export interface CommunicationTemplateDto {
  id: number;
  name: string;
  type: CommunicationType;
  provider: CommunicationProviderDto;
  settings?: object;
  text?: string;
  validFrom?: Date;
  validTo?: Date;
  checkSegmentVersion?: boolean;
}

export interface CommunicationTemplateResponse
  extends Omit<CommunicationTemplateDto, 'validFrom' | 'validTo'> {
  validFrom?: string;
  validTo?: string;
}

export interface GetTemplatesParameters {
  limit?: number;
  offset?: number;
  include?: {
    template?: true;
    settings?: true;
  };
}

export interface CommunicationProviderDto {
  id: number;
  name: string;
  typeId: number;
  channel: CommunicationChannel;
}

export interface TemplateDto {
  name: string;
  type: number;
  provider: CommunicationProviderDto;
  text: string;
}

export interface AudienceDto {
  segmentId: number | null;
  segmentVersionId: number | null;
  count: number;
}

export interface EventsCountDto {
  type: CommunicationActivityType;
  count: number;
}

export interface CommunicationStatisticsEventsDto {
  total: EventsCountDto[] | null;
  unique: EventsCountDto[] | null;
}

export interface CommunicationStatisticsDto {
  totalSends: number;
  events: CommunicationStatisticsEventsDto;
}

export interface CommunicationMessageDto {
  id: number;
  startedAt: Date;
  finishedAt: Date | null;
  status?: CommunicationDispatchStatus;
  template: TemplateDto;
  audience: AudienceDto;
  statistics: CommunicationStatisticsDto;
}

export interface CommunicationMessageDtoResponse
  extends Omit<CommunicationMessageDto, 'startedAt' | 'finishedAt'> {
  startedAt: string;
  finishedAt: string | null;
}

export interface CommunicationTemplateMessageDto {
  id: number;
  startedAt: Date;
  templateId: number;
  status?: CommunicationDispatchStatus;
  audience: AudienceDto;
}

export interface GetMessageSendParams {
  limit?: number;
  offset?: number;
  statusId?: CommunicationIdentifierStatus;
}

export interface CommunicationMessageSendsDto {
  id: number;
  identifier: {
    id: number;
    value: string;
    typeId: number;
  };
  status: {
    id: CommunicationIdentifierStatus;
    errorText: string;
  };
}

import { CommunicationActivityType } from './models';

export const COMMUNICATION_EVENT_TRANSLATION = {
  [CommunicationActivityType.NotSent]: 'communicationEvent.notSent',
  [CommunicationActivityType.Sent]: 'communicationEvent.sent',
  [CommunicationActivityType.Delivered]: 'communicationEvent.delivered',
  [CommunicationActivityType.Opened]: 'communicationEvent.opened',
  [CommunicationActivityType.Click]: 'communicationEvent.click',
  [CommunicationActivityType.Spam]: 'communicationEvent.spam',
  [CommunicationActivityType.Unsubscribed]: 'communicationEvent.unsubscribed',
  [CommunicationActivityType.Subscriptions]: 'communicationEvent.subscriptions',
  [CommunicationActivityType.Confirmations]: 'communicationEvent.confirmations',
  [CommunicationActivityType.Dismiss]: 'communicationEvent.dismiss'
} as Record<CommunicationActivityType, string>;

export const UNIQUE_COMMUNICATION_EVENT_TRANSLATION = {
  [CommunicationActivityType.NotSent]: 'communicationEvent.uniqueNotSent',
  [CommunicationActivityType.Sent]: 'communicationEvent.uniqueSent',
  [CommunicationActivityType.Delivered]: 'communicationEvent.uniqueDelivered',
  [CommunicationActivityType.Opened]: 'communicationEvent.uniqueOpened',
  [CommunicationActivityType.Click]: 'communicationEvent.uniqueClick',
  [CommunicationActivityType.Spam]: 'communicationEvent.uniqueSpam',
  [CommunicationActivityType.Unsubscribed]:
    'communicationEvent.uniqueUnsubscribed',
  [CommunicationActivityType.Subscriptions]:
    'communicationEvent.uniqueSubscriptions',
  [CommunicationActivityType.Confirmations]:
    'communicationEvent.uniqueConfirmations',
  [CommunicationActivityType.Dismiss]: 'communicationEvent.uniqueDismiss'
} as Record<CommunicationActivityType, string>;
